import React from 'react';
import Form from './Form';
import Preview from './Preview';
import './App.css';
import {Flex, Box, Heading} from 'rebass'
import buildUrl from './buildUrl'
class App extends React.Component {

  constructor(props){
    super()

    this.state = {
      to: '',
      subject:'',
      body: '',
      tinyUrl: null
    }
  }
  getTinyUrl = async (url, alias = '') => {
    const tinyUrl = await fetch(`https://tinyurl.com/api-create.php?source=indexpage&url=${url}&alias=${alias}`,{
      method:'post',
    }).then(r => r.text())
    this.setState({
      tinyUrl
    })
  }
  render(){
    const {to,subject,body,tinyUrl} = this.state;
    const url = buildUrl({to,subject,body})
    
    return (
      <div className="App">
        <Flex className="logoflex">
          <Box
            p={5}
            fontSize={4}
            width={[ 1 ]}
            color='primary'
            bg='black'
            className="titlewrapper"

          >
            <Heading
              as="h1"
              color='primary'
              textAlign="center"
              className="title"
            >
              LINK2.EMAIL
             </Heading> 
          </Box>
        </Flex>

        <Flex className="mainflex">

          <Box
            p={5}
            fontSize={4}
            width={[ 1, 1, 1/2 ]}
            color='primary'
            bg='black'
          >
      
            <Form 
              to={to}
              subject={subject}
              body={body}
              onChange={(k,v) => {
                this.setState({
                  [k]: v
                })
              }}
            />

          </Box>
          <Box
            p={5}
            fontSize={4}
            width={[ 1, 1, 1/2 ]}
            color='secondary'
            bg='black'
          >
            <Preview 
              url={url}
              to={to}
              subject={subject}
              body={body}
              tinyUrl={tinyUrl}
              getTinyUrl={this.getTinyUrl}
            />
          </Box>
        </Flex>
          
      </div>
    );
  }
}

export default App;
