import React from 'react'
import { Label, Input, Textarea } from '@rebass/forms'
import {Box,Flex} from 'rebass'

export default ({to,subject,body,onChange}) => (
  <Box className="formwrapper"> 
    <Flex
    className="flexfield"
      marginBottom={20}
    >
      <Label htmlFor='to'>To</Label>
      <Input
        id='to'
        name='to'
        type='email'
        value={to}
        onChange={e => onChange('to',e.target.value)}
      />
    </Flex>
    <Flex
      className="flexfield"
      marginBottom={20}
    >
      <Label htmlFor='subject'>Subject</Label>
      <Input
        id='subject'
        name='subject'
        value={subject}
        onChange={e => onChange('subject',e.target.value)}

      />
    </Flex>
    <Flex
      marginBottom={20}
      className="flexfield"

    >
      <Label htmlFor='body'>Body</Label>
      <Textarea
        id='body'
        name='body'
        value={body}
        onChange={e => onChange('body',e.target.value)}
        height={400}
      />
    </Flex>
  </Box>
)