import React from 'react'
import {Box,Flex,Button} from 'rebass'
import { QRCode } from 'react-qrcode'
import copy from 'copy-to-clipboard';

export default ({to,subject,body, url,tinyUrl,getTinyUrl}) => (
    <Flex
    mb={3}
    flexDirection="column"
    >
    
        {url ? (
            <Box
            color='white'
            width={1}
            borderColor='primary'
            pb={5}
            className="buttonsholder"
          >
          <>
            <Button 
              sx={{
                ':hover': {
                  backgroundColor: 'primary',
                }
              }}
              variant='secondary'
              as='a'
              href={url}
              target="_blank"
              mr={4}
            >TEST IT OUT</Button>

             <Button
            sx={{
              ':hover': {
                backgroundColor: 'primary',
              }
            }}
             variant='outline'
             onClick={async e => {
                getTinyUrl(url)
             }}
             mr={4}
            >GET TINYURL</Button>
           
{/*            
            <Button
              sx={{
              ':hover': {
                backgroundColor: 'primary',
              }
            }}
             variant='outline'
             onClick={async e => {
                console.log(e);
                copy(tinyUrl || url)
             }}
             mr={4}
            >COPY</Button> */}
          </>
          </Box>
        ) : null}
     
      <div className="previewlinkbox">
        <Flex
          mb={3}
          flexDirection="row"
          alignItems="flex-start"
          className="previewFlex"
        >
          <Box
            color='white'
            as='pre' 
            width={1}
            borderColor='primary'
          > 
            {url ? (
            <a href={url} className="previewlink">
              {url}
            </a> 
            ) : null} 
            {!url ? (
              <p style={{fontSize:20}}>Fill in the form to get your link!</p>
            ) : null}
          </Box>
          {url ? ( <QRCode
            value={url} 
            color={{ dark: '#df272c', light: '#000' }}
            width={300} 
            height={300} 
           /> ) : null}
        </Flex>

      </div>
      {tinyUrl ? (
          <div className="tinylinkbox">
          <Flex
          color='white'
          width={1}
          borderColor='primary'
          flexDirection="row"
        justifyContent='space-between'
        > 
          <Box
            color='white'
            as='pre' 
          > 
            <a href={tinyUrl} className="previewlink">
            {tinyUrl}
          </a>
          </Box>
          <Button
              sx={{
              ':hover': {
                backgroundColor: 'primary',
              }
            }}
             variant='outline'
             className='copyButton'
             onClick={async e => {
                console.log(e);
                copy(tinyUrl || url)
             }}
             mr={4}
            >COPY</Button>
        </Flex>
        </div>
      ) : null}
    

    </Flex>



 )