import React from 'react'
import { ThemeProvider } from 'emotion-theming'
/* 

font-family: 'Electrolize', sans-serif;
font-family: 'Iceland', cursive;
font-family: 'Oxanium', cursive;
font-family: 'Quantico', sans-serif;
font-family: 'Share', cursive;

 */
const colors = {
  white: '#f1f5de',
  red: '#df272c',
  black: '#0a0a0a',
  grey: '#a09b91'
}
const theme = {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64
  ],
  colors: {
    background: colors.white,
    primary: colors.red,
    secondary: colors.grey,
    color: colors.black
  },
  space: [
    0, 4, 8, 16, 32, 32, 128, 256
  ],
  fonts: {
    body: 'Share, sans-serif',
    heading: 'Electrolize',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)'
  },
  variants: {
  },
  text: {
  },
  buttons: {

    primary: {
      color: colors.white,
      bg: 'transparent',
      border: `2px solid ${colors.white}`,
      fontFamily: 'Oxanium',
    },
    outline: {
      color: colors.white,
      bg: 'transparent',
      border: `2px solid ${colors.white}`,
      fontFamily: 'Oxanium',
    },
    secondary: {
      color: colors.white,
      bg: 'transparent',
      border: `2px solid ${colors.white}`,
      fontFamily: 'Oxanium',
    }
  },
  forms: {
    input: {
      color: colors.white,
      borderRadius: 7,
      borderColor: colors.white,
      borderWidth: 4,
      padding: 11,
      fontSize: 28,
      fontFamily: 'monospace'
    },
    select: {
      borderRadius: 9999,
    },
    textarea: {
      borderRadius: 7,
      borderColor: colors.white,
      borderWidth: 4,
      color: colors.white,
      padding: 11,
      fontSize: 20,
      fontFamily: 'monospace'

    },
    label: {
      fontFamily: 'Oxanium',
      fontSize:14,
      fontWeight:700,
      color: colors.white,
      textTransform: 'uppercase',
      marginBottom:20,
      width:150,
      paddingTop:0.5,
      textAlign:'right',
      marginRight:0
      

    },
    radio: {},
    checkbox: {},
  },
}
export default props =>
  <ThemeProvider theme={theme}>
    {props.children}
  </ThemeProvider>
